<template>
  <v-container fluid class="pa-0 pt-2">
    <v-row>
      <v-col cols="6" class="pb-0">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table width="100%">
            <tr>
              <th
                colspan="2"
                class="font-size-16 color-custom-blue grey lighten-4 py-1 text-uppercase"
              >
                Other Information
              </th>
            </tr>
            <tr
              v-for="(field, index) in tOneFields"
              :key="'tOneFields' + index"
            >
              <td
                class="font-size-18 font-weight-500 py-1"
                style="width: 180px"
              >
                {{ field.title }}
              </td>
              <td class="font-size-18 py-1">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="field.child && detail[field.key]">
                    {{ detail[field.key][field.child] }}
                  </template>
                  <template v-else-if="detail[field.key]">
                    <template
                      v-if="
                        field.key == 'end_life' ||
                        field.key == 'activation_date' ||
                        field.key == 'installation_date'
                      "
                    >
                      {{ formatDate(detail[field.key]) }}
                    </template>
                    <template v-else-if="field.key == 'serial_no'">
                      <v-chip color="cyan" label small text-color="white">
                        {{ detail[field.key] }}
                      </v-chip>
                    </template>
                    <template v-else-if="field.key == 'location'">
                      {{ detail[field.key].text }}
                    </template>
                    <template v-else-if="field.key == 'property'">
                      {{ detail[field.key].property_address }}
                    </template>
                    <template v-else-if="field.key == 'customer'">
                      {{ detail[field.key].company_name }}
                    </template>
                    <template v-else>
                      {{ detail[field.key] }}
                    </template>
                  </template>
                  <!-- <template v-else-if="field.key == 'end_life'">
                    {{ formatDate(detail[field.key]) }}
                  </template> -->
                  <!--  <template v-else-if="detail[field.key]">
                    {{ detail[field.key] }}
                  </template> -->
                  <template v-else>
                    <em class="text-muted">No {{ field.title }}</em>
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <th colspan="2" class="font-size-19 py-1"></th>
            </tr>
            <tr>
              <th
                colspan="2"
                class="font-size-16 color-custom-blue grey lighten-4 py-1 text-uppercase"
              >
                Delivery Information
              </th>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Do No.</td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.do_no">
                  {{ detail.do_no }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Do No. </em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">
                Commission Certificate No.
              </td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.common_cert_no">
                  {{ detail.common_cert_no }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Commission Certificate No.</em>
                </template>
              </td>
            </tr>
            <!-- <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Sales Employee</td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.sales_employee">
                  {{ detail.sales_employee }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Sales Employee</em>
                </template>
              </td>
            </tr> -->
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Arrival Date</td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.arrival_date">
                  {{ formatDate(detail.arrival_date) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Arrival Date</em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Delivery Date</td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.delivery_date">
                  {{ formatDate(detail.delivery_date) }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Delivery Date</em>
                </template>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1 d-flex">
                Delivery Remark
              </td>
              <td class="font-size-18 py-1">
                <template v-if="detail && detail.delivery_remark">
                  {{ detail.delivery_remark }}
                </template>
                <template v-else>
                  <em class="text-muted"> no Delivery Remark</em>
                </template>
              </td>
            </tr>
            <tr>
              <th colspan="2" class="font-size-18 py-1"></th>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="6" class="pb-0">
        <div class="gray-background border-left">
          <perfect-scrollbar
            ref="sidebarScrollbar"
            :options="{ suppressScrollX: true }"
            class="scroll h-100"
            style="position: relative"
          >
            <table
              class="width-100 mt-0"
              v-if="detail && detail.customer && detail.customer.id"
            >
              <tr>
                <th
                  colspan="2"
                  class="font-size-16 color-custom-blue py-1 text-uppercase"
                >
                  Customer Information
                </th>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-1">Customer #</td>
                <td class="font-size-18 py-1">
                  <template
                    v-if="detail && detail.customer && detail.customer.barcode"
                  >
                    <Barcode
                      route="customer.detail"
                      :barcode="detail.customer.barcode"
                      :id="detail.customer.id"
                    >
                    </Barcode>
                  </template>
                  <template v-else> no serial no </template>
                </td>
              </tr>
              <template v-if="detail?.customer?.company_type == 'personal'">
                <tr class="">
                  <td class="font-size-18 font-weight-500 py-1">
                    Display Name
                  </td>
                  <td class="font-size-18 py-1 text-capitalize">
                    <template
                      v-if="
                        detail &&
                        detail.customer &&
                        detail.customer?.default_person?.display_name
                      "
                    >
                      {{ detail.customer?.default_person?.display_name }}
                    </template>
                    <template v-else> no display name </template>
                  </td>
                </tr>
                <tr class="">
                  <td class="font-size-18 font-weight-500 py-1">
                    Email Address
                  </td>
                  <td class="font-size-18 py-1">
                    <template
                      v-if="
                        detail &&
                        detail.customer &&
                        detail.customer?.default_person?.primary_email
                      "
                    >
                      {{ detail.customer?.default_person?.primary_email }}
                    </template>
                    <template v-else>
                      <em class="text-muted"> no email </em>
                    </template>
                  </td>
                </tr>
                <tr class="">
                  <td class="font-size-18 font-weight-500 py-1">
                    Phone Number
                  </td>
                  <td class="font-size-18 py-1">
                    <template
                      v-if="
                        detail &&
                        detail.customer &&
                        detail?.customer?.default_person?.primary_phone
                      "
                    >
                      {{ detail?.customer?.default_person?.primary_phone }}
                    </template>
                    <template v-else>
                      <em class="text-muted"> no phone number</em>
                    </template>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr class="">
                  <td class="font-size-18 font-weight-500 py-1 text-capitalize">
                    Display Name
                  </td>
                  <td class="font-size-18 py-1">
                    <template
                      v-if="
                        detail &&
                        detail.customer &&
                        detail.customer.display_name
                      "
                    >
                      {{ detail.customer.display_name }}
                    </template>
                    <template v-else> no display name </template>
                  </td>
                </tr>
                <tr class="">
                  <td class="font-size-18 font-weight-500 py-1">
                    Email Address
                  </td>
                  <td class="font-size-18 py-1">
                    <template
                      v-if="
                        detail &&
                        detail.customer &&
                        detail.customer.company_email
                      "
                    >
                      {{ detail.customer.company_email }}
                    </template>
                    <template v-else>
                      <em class="text-muted"> no email </em>
                    </template>
                  </td>
                </tr>
                <tr class="">
                  <td class="font-size-18 font-weight-500 py-1">
                    Phone Number
                  </td>
                  <td class="font-size-18 py-1">
                    <template
                      v-if="
                        detail &&
                        detail.customer &&
                        detail.customer.company_number
                      "
                    >
                      {{ detail.customer.company_number }}
                    </template>
                    <template v-else>
                      <em class="text-muted"> no phone number</em>
                    </template>
                  </td>
                </tr>
              </template>

              <tr>
                <td valign="middle" class="py-1 font-size-18 font-weight-500">
                  Website
                </td>
                <td valign="middle" class="py-1 font-size-18">
                  <template
                    v-if="detail && detail.customer && detail.customer.website"
                  >
                    {{ detail.customer.website }}
                  </template>
                  <em v-else class="text-muted"> no website</em>
                </td>
              </tr>
            </table>
            <table
              class="width-100"
              v-if="
                false &&
                detail &&
                detail.has_warranty == 1 &&
                detail.warranty &&
                detail.warranty.start_date &&
                false
              "
            >
              <tr>
                <th
                  colspan="2"
                  class="font-size-19 color-custom-blue py-1 text-uppercase"
                >
                  Warranty Information
                </th>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-1" width="150px">
                  Warranty #
                </td>
                <td class="font-size-18 py-1">
                  <template
                    v-if="
                      detail && detail.warranty && detail.warranty.unique_id
                    "
                  >
                    <Barcode
                      route="warranty.detail"
                      :barcode="detail.warranty.unique_id"
                      :id="detail.warranty.id"
                    >
                    </Barcode>
                  </template>
                  <template v-else> no serial no </template>
                </td>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-1" width="200">
                  Start Date
                </td>
                <td class="font-size-18 py-1">
                  <template
                    v-if="
                      detail && detail.warranty && detail.warranty.start_date
                    "
                  >
                    <v-chip outlined text-color="" label small color="green">
                      {{ formatDate(detail.warranty.start_date) }}
                    </v-chip>
                  </template>
                  <template v-else> no start date </template>
                </td>
              </tr>
              <tr class="">
                <td valign="top" class="font-size-18 font-weight-500 py-1">
                  End Date
                </td>
                <td class="font-size-18 py-1">
                  <template
                    v-if="detail && detail.warranty && detail.warranty.end_date"
                  >
                    <v-chip outlined class="mr-2" label small color="red">
                      {{ formatDate(detail.warranty.end_date) }}
                    </v-chip>
                    <span
                      class="red--text font-weight-700 d-inline-block text-nowrap py-1"
                      v-if="totalDays(detail.warranty.end_date) > 0"
                    >
                      ( {{ totalDays(detail.warranty.end_date) }} Days )
                    </span>
                  </template>
                  <template v-else> no End date </template>
                </td>
              </tr>
              <tr class="">
                <td valign="top" class="font-size-18 font-weight-500 py-1">
                  Cost
                </td>
                <td class="font-size-18 py-1">
                  <template
                    v-if="detail && detail.warranty && detail.warranty.cost"
                  >
                    <v-chip outlined text-color="" label small color="blue">
                      {{ formatMoney(detail.warranty.cost) }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ detail.pm_one_serial_number }}
                  </template>
                </td>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-1">Status</td>
                <td class="font-size-18 py-1">
                  <template
                    v-if="detail && detail.warranty && detail.warranty.status"
                  >
                    <v-chip
                      small
                      label
                      text-color="white"
                      :color="getStatusColor(detail.warranty.status)"
                      v-if="detail.warranty.status"
                    >
                      {{ getStatusText(detail.warranty.status) }}
                    </v-chip>
                  </template>
                </td>
              </tr>
            </table>

            <!-- Preventive Maintenance -->

            <table class="width-100" v-if="detail.is_purchase_info == 1 && 0">
              <tr>
                <th colspan="2" class="font-size-18 py-1"></th>
              </tr>
              <tr>
                <th colspan="2" class="font-size-18 py-1">
                  Purchased Information
                </th>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-1">Supplier</td>
                <td class="font-size-18 py-1">
                  <template
                    v-if="
                      detail && detail.supplier && detail.supplier.display_name
                    "
                  >
                    {{ detail.supplier.display_name }}
                  </template>
                  <em class="text-muted" v-else> no supplier </em>
                </td>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-1">Purchased On</td>
                <td class="font-size-18 py-1">
                  <template v-if="detail && detail.purchased_on">
                    {{ formatDate(detail.purchased_on) }}
                  </template>
                  <em class="text-muted" v-else> no purchased date </em>
                </td>
              </tr>
              <tr class="">
                <td class="font-size-18 font-weight-500 py-1">Cost Price</td>
                <td class="font-size-18 py-1">
                  <template v-if="detail && detail.cost_price">
                    {{ formatMoney(detail.cost_price) }}
                  </template>
                  <em v-else class="text-muted"> no cost price</em>
                </td>
              </tr>
            </table>

            <table class="width-100" v-if="false">
              <tr>
                <th colspan="2" class="font-size-18 py-1"></th>
              </tr>
              <tr>
                <th colspan="2" class="font-size-18 py-1" v-if="detail.faulty">
                  Faulty Remark
                </th>
                <th colspan="2" class="font-size-18 py-1" v-if="detail.retired">
                  Retired Remark
                </th>
              </tr>
              <tr class="">
                <!-- <td class="font-size-18 font-weight-500 py-1" width="250">
                  Remark
                </td> -->
                <td
                  class="font-weight-600 font-size-18 py-1"
                  style="line-height: normal"
                >
                  <template v-if="detail && detail.faulty">
                    <span>{{ detail.faulty_remark }}</span>
                  </template>
                  <template v-else-if="detail && detail.retired">
                    <span>{{ detail.retired_remark }}</span>
                  </template>
                  <em class="text-muted" v-else> no remark </em>
                </td>
              </tr>
            </table>
          </perfect-scrollbar>
        </div>
      </v-col>

      <v-col cols="6">
        <div class="gray-background h-100 border-left">
          <table class="width-100">
            <tr>
              <th
                colspan="2"
                class="font-size-16 color-custom-blue py-1 text-uppercase"
              >
                Customer Asset Sales Information
              </th>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1" width="200">
                Sales Person
              </td>
              <td class="font-weight-600 font-size-18 py-1">
                <template v-if="detail && detail.salesperson">
                  {{ detail.salesperson }}
                </template>
                <em class="text-muted" v-else> no sales person </em>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Supplier</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template
                  v-if="
                    detail && detail.supplier && detail.supplier.display_name
                  "
                >
                  {{ detail.supplier.display_name }}
                </template>
                <em class="text-muted" v-else> no supplier </em>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Purchase Date</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template v-if="detail && detail.purchase_date">
                  {{ detail.purchase_date }}
                </template>
                <em class="text-muted" v-else> no purchased date </em>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Purchase Price</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template v-if="detail && detail.purchase_price">
                  <v-chip outlined text-color="" label small color="blue">
                    {{ formatMoney(detail.purchase_price) }}
                  </v-chip>
                </template>
                <em v-else class="text-muted"> no Purchase Price</em>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Quotation No.</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template v-if="detail && detail.quotation_no">
                  {{ detail.quotation_no }}
                </template>
                <em v-else class="text-muted"> no quotation no.</em>
              </td>
            </tr>
            <tr class="">
              <td class="font-size-18 font-weight-500 py-1">Invoice No.</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template v-if="detail && detail.invoice_no">
                  {{ detail.invoice_no }}
                </template>
                <em v-else class="text-muted"> no invoice no.</em>
              </td>
            </tr>
          </table>
        </div>
      </v-col>

      <v-col
        cols="6"
        v-if="
          detail &&
          detail.has_warranty == 1 &&
          detail.warranty &&
          detail.warranty.start_date
        "
      >
        <div class="gray-background h-100 border-left">
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-16 color-custom-blue py-1">
                Warranty Information
                <template
                  v-if="detail && detail.warranty && detail.warranty.status"
                >
                  <v-chip
                    small
                    label
                    text-color="white"
                    :color="getStatusColor(detail.warranty.status)"
                    v-if="detail.warranty.status"
                  >
                    {{ getStatusText(detail.warranty.status) }}
                  </v-chip>
                </template>
              </th>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-1" width="200px">
                Warranty #
              </td>
              <td class="font-weight-600 font-size-18 py-1">
                <template
                  v-if="detail && detail.warranty && detail.warranty.unique_id"
                >
                  <Barcode
                    route="warranty.detail"
                    :barcode="detail.warranty.unique_id"
                    :id="detail.warranty.id"
                  >
                  </Barcode>
                </template>
                <template v-else> no serial no </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-1">Start Date</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template
                  v-if="detail && detail.warranty && detail.warranty.start_date"
                >
                  <v-chip outlined text-color="" label small color="green">
                    {{ formatDate(detail.warranty.start_date) }}
                  </v-chip>
                </template>
                <template v-else> no start date </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-1">End Date</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template
                  v-if="detail && detail.warranty && detail.warranty.end_date"
                >
                  <v-chip outlined class="mr-2" label small color="blue">
                    {{ formatDate(detail.warranty.end_date) }}
                  </v-chip>
                  <span
                    class="blue--text font-weight-700 text-nowrap py-1 d-inline-block"
                    v-if="totalDays(detail.warranty.end_date) > 0"
                  >
                    ( {{ totalDays(detail.warranty.end_date) }} Days )
                  </span>
                </template>
                <template v-else> no End date </template>
              </td>
            </tr>
            <tr class="line-height5">
              <td class="font-size-18 font-weight-500 py-1">Cost</td>
              <td class="font-weight-600 font-size-18 py-1">
                <template
                  v-if="detail && detail.warranty && detail.warranty.cost"
                >
                  <v-chip outlined text-color="" label small color="blue">
                    {{ formatMoney(detail.warranty.cost) }}
                  </v-chip>
                </template>
                <template v-else>
                  {{ detail.pm_one_serial_number }}
                </template>
              </td>
            </tr>
          </table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { toSafeInteger } from "lodash";
/* import { QUERY } from "@/core/services/store/request.module"; */
import CommonMixin from "@/core/plugins/common-mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  data() {
    return {
      equipment: 0,
      dataLoading: true,
      chartOptions: {
        chart: {
          id: "equipment-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Specification",
          key: "specification",
          child: false,
        },
        {
          title: "Brand",
          key: "brand",
          child: false,
        },
        // {
        //   title: "Model",
        //   key: "model",
        //   child: false,
        // },
        {
          title: "Category",
          key: "category",
          child: false,
        },
        // {
        //   title: "Contact For Item",
        //   key: "company_number",
        //   child: false,
        // },
        {
          title: "Description",
          key: "description",
          child: false,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.equipment = this.lodash.toSafeInteger(param.id);
        this.dataLoading = false;
      },
    },
  },
  methods: {
    getProfileImage() {
      if (!this.detail.image) {
        return null;
      }
      return this.detail.image;
    },
    totalDays(end_date) {
      var a = moment(end_date, "yyyy-MM-DD");
      var b = moment();
      var final = a.diff(b, "days");
      return final;
    },
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }

      if (row.country) {
        tempArr.push(row.country);
      }
      return tempArr.join(", ");
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.detail.entity.unit_no) {
        tempArr.push(this.detail.entity.unit_no);
      }
      if (this.detail.entity.street_1) {
        tempArr.push(this.detail.entity.street_1);
      }
      if (this.detail.entity.street_2) {
        tempArr.push(this.detail.entity.street_2);
      }

      if (this.detail.entity.zip_code) {
        tempArr.push(this.detail.entity.zip_code);
      }
      if (this.detail.entity.landmark) {
        tempArr.push(this.detail.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
  },
  mounted() {
    /*  this.getSummary(); */
  },
  computed: {
    equipmentActivated() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.detail) === false) {
        return _this.detail.activated == 1 ? true : false;
      }
      return false;
    },
  },
  components: {
    Barcode,
  },
};
</script>
